import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

export const ContentImage = ({ image, caption, customClass }) => {
  let activeCaption = caption;
  if (caption.length === 0) {
    activeCaption = image.title;
  }

// 	const [activeCaption, setActiveCaption] = useState(“”)
// useEffect(() => { setActiveCaption(image?.title) }, [caption])

  let blockClass = 'content-image col';
  if (customClass) {
    blockClass = customClass;
  }
  return (
    <div className={`content-image-wrapper ${blockClass}`}>
      <Img fluid={{ ...image.fluid }} alt={image.alt} />
      <div
        className="caption"
        dangerouslySetInnerHTML={{
          __html: activeCaption,
        }}
      />
    </div>
  );
};

ContentImage.propTypes = {
  image: PropTypes.shape().isRequired,
  caption: PropTypes.string,
  customClass: PropTypes.string,
};

ContentImage.defaultProps = {
  caption: '',
  customClass: null,
};
