import React from 'react';
import PropTypes from 'prop-types';

export const ContentText = ({ text, customClass }) => {
  // let blockClass = 'offset-sm-2 col-sm-7 col-xs-6 col';
  let blockClass = 'content-text col';
  if (customClass) {
    blockClass = customClass;
  }
  return (
    <div className={`content-text-wrapper ${blockClass}`}>
      <div
        className="content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  );
};

ContentText.propTypes = {
  text: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};

ContentText.defaultProps = {
  customClass: null,
};
