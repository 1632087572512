import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

export const ContentVideo = ({ video, caption, customClass }) => {
  let activeCaption = caption;
  if (caption.length === 0) {
    activeCaption = video.title;
  }
  // let blockClass = 'offset-sm-4 col-sm-7 offset-xs-1 col-xs-5 col';
  let blockClass = 'content-video col';
  if (customClass) {
    blockClass = customClass;
  }
  return (
    <div className={`content-video-wrapper ${blockClass}`}>
      <div className="responsive-iframe">
        <ReactPlayer
          url={video}
          height="100%"
          width="100%"
          controls
          playsinline
          muted
        />
      </div>
      {caption && (
        <div
          className="sans--small"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: activeCaption,
          }}
        />
      )}
    </div>
  );
};

ContentVideo.propTypes = {
  video: PropTypes.string.isRequired,
  caption: PropTypes.string,
  customClass: PropTypes.string,
};

ContentVideo.defaultProps = {
  caption: '',
  customClass: null,
};
