import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

export const ContentExternalVideo = ({ video, caption, customClass }) => {
  // let blockClass = 'offset-sm-4 col-sm-7 offset-xs-1 col-xs-5 col';
  let blockClass = 'content-external-video col';
  if (customClass) {
    blockClass = customClass;
  }
  return (
    <div className={`content-external-video-wrapper ${blockClass}`}>
      <ReactPlayer
        url={video.url}
        height="100%"
        width="100%"
        controls
        muted
        loop
        title={video.title}
        className="responsive-iframe"
      />
      {caption && (
        <div
          className="caption"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        />
      )}
    </div>
  );
};

ContentExternalVideo.propTypes = {
  video: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  caption: PropTypes.string,
  customClass: PropTypes.string,
};

ContentExternalVideo.defaultProps = {
  caption: '',
  customClass: null,
};
