/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import ReactPlayer from 'react-player';
import Img from 'gatsby-image';
import ImageFadeIn from "react-image-fade-in"

export const RelatedDigitalProjectList = ({ artists, mainProjectId }) => {
  const data = useStaticQuery(graphql`
    query relatedQuery {
      projectData: allDatoCmsSingleDigitalProject(
        sort: { fields: [meta___firstPublishedAt], order: DESC }
        filter: { meta: { isValid: { eq: true }, status: { ne: "draft" } } }
      ) {
        edges {
          node {
            id
            formattedTitle
            slug
            featuredImage {
              alt
							url(imgixParams: { w: "450", ar: "5:3", fit: "crop", crop: "focalpoint" })
              video {
                mp4Url
              }
            }
            artists {
              ... on DatoCmsStudioArtist {
                artist {
                  id
                  slug
                  name
                }
              }
              ... on DatoCmsNonStudioArtist {
                id
                artistName
              }
            }
          }
        }
      }
    }
  `);

  const [featuredProjects, setFeaturedProjects] = useState([]);
  const projectData = data.projectData.edges.map((project) => project.node);
  const projects = projectData.filter((project) => project.id !== mainProjectId);
  const numberToFeature = 4;
  const sidebarTextRef = useRef();

  function isRelated(project) {
    return project.artists.some(
      (artist) => artists.findIndex((projectArtist) => projectArtist.id === artist.id) > -1,
    );
  }

  function getRelatedProjects(projectList) {
    return projectList
      .filter((project) => isRelated(project))
      .slice(0, numberToFeature);
  }

  function getOtherProjects(projectList) {
    const newIndex = projectList.findIndex((project) => project.id === mainProjectId);
    const newerProjects = projects.slice(newIndex - numberToFeature, newIndex + 1);
    // eslint-disable-next-line max-len
    const olderProjects = projects.slice(Math.max(newIndex, 0), Math.max(newIndex, 0) + numberToFeature);
    return newerProjects.length ? newerProjects : olderProjects;
  }

  useEffect(() => {
    const relatedProjects = getRelatedProjects(projects);
    if (relatedProjects.length) {
      setFeaturedProjects(relatedProjects);
    } else {
      sidebarTextRef.current.textContent = 'Other projects';
      const otherProjects = getOtherProjects(projects);
      setFeaturedProjects(otherProjects);
    }
  }, [data.projectData.edges]);

  return (
    <div className="container row">
      <div className="col-sm-2 col-xs-6 col">
        <span className="sidebar-text" ref={sidebarTextRef}>
          Related projects
        </span>
      </div>
      <div className="related-digital-project-list tiles tiles-4 full col-sm-12 col-xs-6 col row spacing-0">
        {featuredProjects.map(({
          slug, id, featuredImage, formattedTitle,
        }) => (
          <Link
            to={`/digital-projects/${slug}`}
            className="tile col"
            key={id}
          >
            {featuredImage.video ? (
              <ReactPlayer
                url={featuredImage.video.mp4Url}
                width="100%"
                height="60%"
                playing
                muted
                loop
                controls={false}
                className="responsive-iframe"
                playsinline
              />
            ) : (
							<ImageFadeIn
								src={featuredImage.url}
								alt={featuredImage.alt}
								className="tile-image"
							/>
            )}
            <div className="tile-text-wrap">
              <h3
                className=""
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: formattedTitle,
                }}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
