/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
// import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { renderArtist } from '../components/artistList';
import { ContentImage } from '../components/content/image';
import { ContentVideo } from '../components/content/video';
import { ContentAudio } from '../components/content/audio';
import { ContentExternalVideo } from '../components/content/externalVideo';
import { ContentExternalVideoFullScreen } from '../components/content/externalVideoFullScreen.tsx';
import { ContentText } from '../components/content/text';
import { DividerBlock } from '../components/dividerBlock';
import { RelatedDigitalProjectList } from '../components/relatedDigitalProjectList';

// eslint-disable-next-line react/prop-types
const DigitalWork = ({ location, data }) => {
  const work = data.datoCmsSingleDigitalProject;
  const relatedArtists = work.artists;
  // eslint-disable-next-line react/prop-types
  const { backLink, backLinkText } = location.state ?? {};

  const buildContent = (obj) => {
    const type = obj.model.name;
    if (type === 'External Video') {
      return (
        <ContentExternalVideo
          key={obj.id}
          video={obj.video}
          caption={obj.workCaption}
          customClass="col-sm-8 offset-sm-2 col-xs-8"
        />
      );
    }
    if (type === 'External Video – Full screen') {
      return (
        <ContentExternalVideoFullScreen
          key={obj.id}
          video={obj.video}
          caption={obj.workCaption}
          cover={obj.coverImage}
          customClass="col-sm-8 offset-sm-2 col-xs-8"
        />
      );
    }
    if (type === 'Image' && obj.image) {
      return (
        <ContentImage
          key={obj.id}
          image={obj.image}
          caption={obj.workCaption}
          customClass="col-sm-7 offset-sm-2 col-xs-8"
        />
      );
    }
    if (type === 'Text') {
      return (
        <ContentText
          key={obj.id}
          text={obj.text}
          customClass="col-sm-7 offset-sm-2 col-xs-6"
        />
      );
    }
    if (type === 'Video') {
      return (
        <ContentVideo
          key={obj.id}
          video={obj.video.video.mp4Url}
          caption={obj.workCaption}
          customClass="col-sm-8 offset-sm-2 col-xs-8"
        />
      );
    }
		if (type === 'Audio') {
      return (
        <ContentAudio
          key={obj.id}
          audioEmbed={obj.audioEmbed}
          caption={obj.workCaption}
          customClass="col-sm-8 offset-sm-2 col-xs-8"
        />
      );
    }

    return null;
  };

  return (
    <Layout
      bgcolor={work.backgroundColor}
      wrapperClass="digital-project"
      accentColor={work.accentColor}
      displayGuides={true}
    >
      <HelmetDatoCms seo={work.seoMetaTags} />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link to="/digital-projects" className="sidebar-text">Digital projects</Link>
        </div>
        <div className="col-sm-9 col-xs-6 col">
          <h1
            className="gothic--xlarge large-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: work.formattedTitle,
            }}
          />
        </div>
      </div>
			{work.description.length > 0 && (
      <div className="digital-project-info container row single-article-content">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          {Boolean(work.content) && (
            <div
              className="content"
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: work.description,
              }}
            />
          )}
        </div>
        <div className="col-sm-3 col-xs-6 offset-sm-1 col">
          <DividerBlock accentColor={work.accentColor} />
        </div>
      </div>
			)}
      {work.content.length > 0 && (
      <div className="digital-project-content container row main-content-wrapper single-article-content">
        <div className="col">
          {work.content.map((content) => buildContent(content))}
        </div>
      </div>
      )}
      {work.artists.length > 0 && (
        <div className="digital-project-artists container row">
          <div className="col-sm-7 offset-sm-2 col-xs-6 col">
            <div className="artist-list-wrapper">
              <p className="artist-title">Artists</p>
              <ul className="artist-list">
                {work.artists.map((artist) => renderArtist(artist))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="digital-project-footer container row">
        <div className="col-sm-12 offset-sm-2 col-xs-6 col">
          <Link
            to={backLink ?? '/digital-projects'}
            className="back-link"
          >
            {`Back to ${backLinkText ?? 'digital projects'}`}
          </Link>
        </div>
      </div>
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-sm-3 col">
          <DividerBlock accentColor={work.accentColor} />
        </div>
      </div>
      <RelatedDigitalProjectList artists={relatedArtists} mainProjectId={work.id} />
    </Layout>
  );
};

DigitalWork.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default DigitalWork;

export const query = graphql`
  query DigitalWorkQuery($slug: String!) {
    datoCmsSingleDigitalProject(slug: { eq: $slug }) {
      id
      backgroundColor
      accentColor {
        hex
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      description
      featuredImage {
        alt
				url(imgixParams: { w: "450", ar: "5:3", fit: "crop", crop: "focalpoint" })
      }
      artists {
        ... on DatoCmsStudioArtist {
          artist {
            id
            slug
            name
          }
        }
        ... on DatoCmsNonStudioArtist {
          id
          artistName
        }
      }
      formattedTitle
      id
      content {
        ... on DatoCmsImage {
          id
          model {
            name
          }
          image {
            title
            alt
            fluid(maxHeight: 500) {
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
          workCaption
        }
        ... on DatoCmsText {
          id
          model {
            name
          }
          text
        }
        ... on DatoCmsVideo {
          id
          model {
            name
          }
          video {
            video {
              mp4Url
            }
          }
          workCaption
        }
        ... on DatoCmsExternalVideo {
          id
          model {
            name
          }
          video {
            url
            providerUid
            title
          }
          workCaption
        }
        ... on DatoCmsExternalVideoFullScreen {
          id
          model {
            name
          }
          video {
            url
            providerUid
            title
          }
          coverImage {
            url(imgixParams: { w: "800", ar: "5:3", fit: "crop", crop: "focalpoint" })
            alt
          }
          workCaption
        }
				... on DatoCmsAudio {
          id
          model {
            name
          }
          audioEmbed
          workCaption
        }
      }
    }
  }
`;
