import React from 'react';
import PropTypes from 'prop-types';

export const ContentAudio = ({ audioEmbed, caption, customClass }) => {
  // let blockClass = 'offset-sm-2 col-sm-7 col-xs-6 col';
  let blockClass = 'content-audio col';
  if (customClass) {
    blockClass = customClass;
  }
  return (
    <div className={`content-audio-wrapper ${blockClass}`}>
      <div
        className="content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: audioEmbed,
        }}
      />
			{caption && (
        <div
          className="caption"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        />
      )}
    </div>
  );
};

ContentAudio.propTypes = {
  audioEmbed: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};

ContentAudio.defaultProps = {
  customClass: null,
};
