import React from 'react';
import { Link } from 'gatsby';

export const renderArtist = (artist) => {
  let thisArtist = artist;
  if (artist.artist) {
    thisArtist = artist.artist;
  }

  if (thisArtist.slug) {
    return <li key={thisArtist.id}><Link to={`/artist/${thisArtist.slug}`}>{thisArtist.name}</Link></li>;
  }

  return <li key={thisArtist.id}><span>{thisArtist.artistName}</span></li>;
};
