import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player';
import ImageFadeIn from 'react-image-fade-in';
import PlayCover from '../../images/play-cover.inline.svg';
import PropTypes from 'prop-types';
import FadeInOut from '../../hooks/fadeInOut';

export const ContentExternalVideoFullScreen = ({ video, caption, cover, customClass }) => {
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  useEffect(() => {
    document.body.classList.toggle('popup-open', showPopUp);
  },[showPopUp])
  let blockClass = 'content-external-video-fullscreen col';
  if (customClass) {
    blockClass = customClass;
  }
  return (
    <div className={`content-external-video-wrapper ${blockClass}`}>
      <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-7 lg:col-start-3 3xl:col-span-6 3xl:col-start-4">
        <button
          className="play-video"
          onClick={() => setShowPopUp(true)}
        >
          <PlayCover className="play-cover"/>
          <ImageFadeIn src={cover.url} alt={ "Play video"|| cover.alt} />
        </button>
        <div
          className="caption"
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        />
      </div>
      {showPopUp && (
        <FadeInOut show={showPopUp} duration={300}>
        <div className="popup" onClick={() => setShowPopUp(false)}>
          <button
            className="close-menu"
            onClick={() => setShowPopUp(false)}
          >
            <p className="close-text">Close</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.746094 1L22.7461 23" stroke="white" strokeWidth="2" />
              <path d="M22.7461 1L0.746095 23" stroke="white" strokeWidth="2" />
            </svg>
          </button>
          <div className="video-wrapper">
            <ReactPlayer
              url={video.url}
              height="100%"
              width="100%"
              controls
              loop
              playing={true}
              title={video.title}
              className="responsive-iframe"
            />
          </div>
        </div>
        </FadeInOut>
      )}
    </div>
  )
};

ContentExternalVideoFullScreen.propTypes = {
  video: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  caption: PropTypes.string,
  customClass: PropTypes.string,
  cover: PropTypes.string,
};

ContentExternalVideoFullScreen.defaultProps = {
  caption: '',
  customClass: null,
};
